// i18next-extract-mark-ns-start installment-payments-for-woocommerce

//Landing redirected to /payment-methods to hide from BdE license - Discussed with David and Alex on 2024-08-08

//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import woocommerce_cofidis from 'images/woocommerce_cofidis.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="installment-payments-for-woocommerce"
        title={t('Get WooCommerce Installment Payments | MONEI')}
        description={t(
          'Add WooCommerce installments payments to your store to let customers pay in 4 while you get the money upfront. You’ll boost customer satisfaction and sales. Start now ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Accept WooCommerce installment payments</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Adding installment payments to your WooCommerce checkout will lead to improved
                customer satisfaction, increased retention rates, and more sales. But you’ll need a
                payment gateway that supports installment payments and integrates with WooCommerce.
              </Trans>
              <Trans parent="p">
                That’s where we come in. Our advanced e-commerce payment gateway lets you accept the
                widest range of online payment methods in your WooCommerce store. Let your customers
                pay in 4 installments while you get paid in full, thanks to our partnership with
                Cofidis 4xcard.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={woocommerce_cofidis}
              alt="Woocommerce cofidis"
              title="Woocommerce cofidis"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>
                  Increase conversions and boost trust with Cofidis installment payments for
                  WooCommerce
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                <InternalPageLink slug="payment-methods/cofidis">Cofidis</InternalPageLink> is
                Spain’s leader in consumer credit and is used and trusted by many. Configure
                WooCommerce installment payments to let shoppers enjoy{' '}
                <BlogLink slug="buy-now-pay-later">buying now and paying later</BlogLink>. You’ll
                always get paid in full when the order is placed, and you’ll never have to worry
                about tracking down customers for money.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Installment Payments by Cofidis: how does it work?</Trans>
              </SectionHeader>
              <Trans parent="p">
                After you create your MONEI account and{' '}
                <AnchorLink href="https://support.monei.com/hc/articles/4402319128465">
                  configure the Cofidis payment method
                </AnchorLink>
                , your customers can select installment payments at checkout for purchases from €75
                to €1,000.
              </Trans>
              <Trans parent="p">
                For shoppers, the{' '}
                <InternalPageLink slug="cofidis">checkout process is simple</InternalPageLink>. All
                they have to do is provide their credit card details and personal ID, and it takes
                less than three minutes to complete the online application.
              </Trans>
              <Trans parent="p">
                The first payment happens at the time of purchase and the balance customer
                transactions occur automatically 30, 60, and 90 days after the initial payment.
                You’ll get paid in full one day after the order is placed.
              </Trans>
              <Trans parent="p">
                For more information about enabling Cofidis and payouts,{' '}
                <AnchorLink href="https://support.monei.com/hc/sections/360006008278">
                  go here
                </AnchorLink>
                .
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Why use MONEI’s e-commerce payment gateway?</Trans>
              </SectionHeader>
              <Trans parent="p">
                As an e-commerce merchant, it’s crucial to accept as many{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                as possible. Aside from pay in 4 options such as Cofidis, modern consumers demand
                more ways to pay online. This includes{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>{' '}
                as well as <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>,
                an increasingly popular local payment method in Spain.
              </Trans>
              <Trans parent="p">
                That’s why we aggregate all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                into one platform that you can manage from a single dashboard. This way, you’ll
                reach more people, provide a better customer experience, and boost sales.
              </Trans>
              <Trans parent="p">
                We’re the only payment gateway to offer dynamic pricing. As your sales increase,
                you’ll spend less on transaction fees. Scaled pricing updates in real-time so you
                can save money to reinvest in your business.
              </Trans>
              <Trans parent="p">
                Choose the most flexible{' '}
                <InternalPageLink slug="woocommerce-payment-gateway">
                  payment gateway for your WooCommerce store
                </InternalPageLink>{' '}
                — <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>
                Connect your WooCommerce store with MONEI to start accepting installment payments
                today
              </Trans>
            </SectionHeader>
            <Trans parent="p">
              Quickly and easily connect your WooCommerce store with MONEI to start accepting more
              payment methods today.
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/articles/4402319128465">
                  Configure Installment Payments by Cofidis
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/woocommerce/">
                  WooCommerce plugin guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: {in: ["common", "installment-payments-for-woocommerce"]}
        language: {eq: $language}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
